<template>
  <div>
    <DataTableFilter :options="options"
                     class="mt-3"/>

    <div style="overflow-x: auto" class="pt-3">

      <b-row class="flex-nowrap"
             no-gutters>
        <b-col v-for="(p, pind) in funnelPatientStatuses"
               :key="`funnel-${pind}`">
          <div class="plan-wrap h-100"
               :class="{ 'ml-3': pind > 0 }">
            <div>
              {{ $t(p.title) }} |
              <span :class="{ 'text-danger': !!patients[p.id] }">
              {{ patients[p.id] ? patients[p.id].length : 0 }}
            </span>
              <div class="separator w-100 mt-2 mb-3"
                   :style="`background-color: ${p.color}`"/>
            </div>

            <draggable class="list-group h-100"
                       :list="patients[p.id]"
                       group="canbans"
                       :data-status="p.id"
                       item-key="canban_id"
                       :move="itemMoved">
              <template #item="{ element }">
                <div class="patient-canban-card card p-md-3 p-2 mb-3 pointer"
                     :data-id="element.id">
                  {{ element }}
                  <!--                  <div class="font-weight-bold">-->
                  <!--                    <router-link :to="`/patients/${element.client_id}/visits`">{{ element.patient.full_name }}</router-link>-->
                  <!--                  </div>-->
                  <!--                  <div>{{ element.patient.phone }}</div>-->
                  <!--                  <div>-->
                  <!--                    <div v-for="visit in element.visits"-->
                  <!--                         :key="`visit-${visit.id}`">-->
                  <!--                      {{ visit.fullTitle }}-->
                  <!--                      <span class="circle mr-2"-->
                  <!--                            :class="`status-${visit.manual_status}`"></span>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div v-if="element.next_visit">-->
                  <!--                    {{ $t('next_visit') }}: {{ element.next_visit.readableStart }}{{ element.next_visit.doctor ? ' - '+element.next_visit.doctor.full_name : '' }}-->
                  <!--                  </div>-->
                  <!--                  <div class="font-weight-bold text-primary pointer" @click="openPlanTreatment(element)">{{ element.message }}</div>-->
                </div>
              </template>
            </draggable>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script setup>
import { funnelPatientStatuses } from "@/dictionaries/dictionary"
import draggable from 'vuedraggable'
import options from "@/data/crm/patient_canban"
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import { useStore } from "vuex"
import { computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useHead } from "@unhead/vue"
import { copyObject } from "@/extensions/prototypes/prototypes"
import { CRMService, EloquentService } from "@/services/api.service"
// import moment from 'moment'
import { useRoute } from "vue-router"

const store = useStore()
const route = useRoute()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('funnel_came_and_repeated')
  draggable.compatConfig = { MODE: 3 }
})

const patients = ref({})
const filters = computed(() => store.state.datatable.filters)
const counter = computed(() => store.state.datatable.dataTableCounter)

onMounted(() => {
  store.commit("setViewModel", 'patient_canban')
  store.commit('setEloquentOptions', options)
  store.commit('setFilters', {})
  loadData()
})

onBeforeUnmount(() => {
  store.commit('setFilters', {})
})

const initialState = () => {
  patients.value = {}
  funnelPatientStatuses.forEach(status => {
    patients.value[status.id] = []
  })
}

const loadData = async () => {
  try {
    initialState()
    let filtersTmp = copyObject(filters.value)
    // if(Object.keys(filtersTmp).length === 0) {
    //   filtersTmp = {
    //     date_add: {
    //       id: 'date_add',
    //       type: 'more',
    //       condition: moment().subtract(90, 'days').format('YYYY-MM-DD')
    //     }
    //   }
    // }
    const data = await CRMService.getPatientCanban(filtersTmp)
    console.log(data.data.data)
    data.data.data.forEach(item => {
      if(!patients.value[item.status]) {
        patients.value[item.status] = []
      }
      patients.value[item.status].push(item)
    })
  } catch (e) {
    console.log(e)
  }
}

const itemMoved = (e) => {
  const itemId = String(e.dragged.getAttribute('data-id'))
  const status = e.to.getAttribute('data-status')
  let formData = new FormData()
  formData = this.appendFormdata(formData, { status })
  EloquentService.update('patient_canban', itemId, formData, false, false)
}

watch(counter, () => {
  loadData()
})

watch(route, () => {
  loadData()
})

//   methods: {
//     openPlanTreatment(plan) {
//       this.$store.commit('setVisit', this.copyObject(plan))
//       this.$store.commit('setPatient', this.copyObject(plan.patient))
//       this.$bvModal.show('plan-treatment-modal')
//     },
</script>

<!--<style scoped lang="scss">-->
<!--.separator {-->
<!--  height: 2px;-->
<!--}-->
<!--.plan-wrap {-->
<!--  min-width: 220px;-->
<!--}-->
<!--.plan-card {-->
<!--  border-radius: 8px;-->
<!--  &:hover {-->
<!--    background-color: $light-blue;-->
<!--  }-->
<!--}-->
<!--.circle {-->
<!--  width: 12px;-->
<!--  height: 12px;-->
<!--  &.status-new {-->
<!--    background: $gray-text;-->
<!--  }-->
<!--  &.status-in_process {-->
<!--    background: $warning-yellow;-->
<!--  }-->
<!--  &.status-finished {-->
<!--    background: #65CA35;-->
<!--  }-->
<!--}-->
<!--@media screen and (max-width: 768px) {-->
<!--  .plan-wrap {-->
<!--    min-width: 150px;-->
<!--  }-->
<!--  .circle {-->
<!--    width: 10px;-->
<!--    height: 10px;-->
<!--  }-->
<!--}-->
<!--</style>-->
