<template>
  <section id="settings" class="settings">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col-9 col-md order-md-0 order-0">
          <h1>{{ $t('crm') }}</h1>
        </div>
        <div class="col-md-auto col-6 mt-md-0 mt-2 order-md-1 order-2 table-view-navigator d-md-block d-none"
             v-if="dateControl">
          <a class="btn-themed btn-themed-outline btn-themed-default d-inline-block w-100 text-center"
             tabindex="#"
             @click="todayClicked">
            {{ $t('today') }}
          </a>
        </div>
        <div class="col-md-auto col-6 mt-md-0 mt-2 order-md-2 order-3 table-view-navigator"
             v-if="dateControl && dateNavigator">
<!--          <SearchTable/>-->
          <CRMDateNavigator ref="navigator"
                            @daySelected="daySelected"/>
        </div>
        <div v-if="['CRMVisits', 'CRMTasks'].includes($route.name)"
             class="col-auto order-md-3 order-4 table-view-navigator">
          <DataTableExcel/>
        </div>
        <div class="col-auto order-md-4 mt-md-0 mt-2 order-4 table-view-navigator">
          <DataTableFilterButton/>
        </div>
        <div v-if="$route.name === 'CRMVisits'" class="col-auto order-md-5 mt-md-0 mt-2 order-5 table-view-navigator">
          <a @click="printPressed" class="btn btn-themed gray d-md-inline-flex d-block align-items-center">
            <PrintSvg class="mr-2 file-svg"/>
            <span>{{ $t('print_noun') }}</span>
          </a>
        </div>
        <div class="col-3 col-md-auto text-right order-md-5 order-1 table-view-navigator" v-if="rights.includes('crm')">
          <AddObject module="crm"/>
        </div>
      </div>
    </div>
    <div class="sub-menu no-print">
      <template v-for="(m, mind) in menu">
        <router-link :to="{ name: m.to }"
                     :class="{ active: m.to === 'CRMSalesFunnelApplications' && $route.name === 'CRMLeadPage' }"
                     v-if="!m.right || rights.includes(m.right)"
                     :key="`crm-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <router-view v-slot="{ Component }">
      <component ref="crmPage" :is="Component" @nullifyDay="nullifyDay"/>
    </router-view>
  </section>
</template>

<script>

import { mapState } from "vuex"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
// import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import CRMDateNavigator from "@/components/parts/crm/CRMDateNavigator"
import PrintSvg from '@/assets/svg-vue/general/print.svg'
import moment from "moment/moment"

export default {
  name: "CRMGeneral",
  components: {
    CRMDateNavigator,
    DataTableFilterButton,
    // SearchTable,
    AddObject,
    PrintSvg,
    DataTableExcel
  },
  data() {
    return {
      menu: [
        { to: 'CRMSalesFunnelApplications', title: 'sales_funnel_applications', right: 'crm' },
        { to: 'CRMTasks', title: 'tasks_and_calls', right: 'tasks' },
        { to: 'CRMVisits', title: 'visits', right: 'tasks' },
        { to: 'CRMApplications', title: 'applications_and_appeals', right: 'crm' },
        { to: 'CRMSalesFunnelPlans', title: 'sales_funnel_plans', right: 'crm' },
        { to: 'CRMForms', title: 'forms', right: 'crm' },
        { to: 'CRMFeedbacks', title: 'feedbacks', right: 'view_feedbacks' },
        { to: 'CRMSettings', title: 'settings', right: 'crm' },
        // { to: 'CRMFunnelPatients', title: 'funnel_came_and_repeated', right: 'crm' },
      ],
      dateNavigator: true
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    dateControl() {
      return this.$route.name === 'CRMTasks'
    }
  },
  methods: {
    daySelected(val) {
      this.$refs.crmPage.daySelected(val)
    },
    todayClicked() {
      this.dateNavigator = true
      this.$refs.crmPage.overdue = false
      this.$refs.crmPage.overdueClicked(false)
      this.$refs.navigator.day = moment()
    },
    nullifyDay(val) {
      this.dateNavigator = val
      // this.$refs.navigator.day = null
    },
    printPressed() {
      if (window) {
        window.print()
      }
    },
  }
}
</script>
