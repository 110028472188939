export default {
    add_call_task: "Qo‘ng‘iroq qilish uchun vazifani qo‘shish",
    all_days: "Barcha kunlar",
    amo_deal_id: "Amo-sotib oluvchi ID-si",
    api_key: "API kaliti",
    applications_and_appeals: "Arizalar va murojaatlar",
    are_you_sure_you_want_to_delete_task: "Haqiqatan ham vazifani o‘chirib tashlamoqchimisiz?",
    attach_file: "Faylni biriktirish",
    binotel: "Binotel",
    binotel_integration: "Binotel bilan integratsiya",
    binotel_integration_instructions: "Dentist Plus-ni Binotel bilan birlashtirish uchun sizga kerak:<br> 1. Binotel bilan hisob yaratish<br>2. Binotel qoʻllab-quvvatlash xizmatiga <b>API CALL SETTINGS</b> va <b>API CALL COMPLETED</b> uchun quyidagi veb-hukni sozlash soʻrovi bilan xat yozing:<br><span class = 'text-primary'>{url}</span><br>quyidagi maydonda binotel funksiyasidan foydalanadigan klinika xodimlaridan foydalanuvchilarni belgilang",
    binotel_key: "Binotel kaliti",
    binotel_secret: "Binotel maxfiy kaliti",
    binotel_internal: "Binotel ichki telefon",
    button_title: "Tugma nomi",
    call_from_patient: "Bemor chaqiruvi",
    call_from_phone: "Raqamlar bilan qo'ng'iroq qiling",
    call_noun: "Qo‘ng‘iroq",
    callee: "Qo‘ng‘iroq qilingan shaxs",
    cancel_sending: "Jo‘natishni bekor qilish",
    change_status: "Holatni o‘zgartirish",
    client_comment: "Mijoz izohi",
    copied: "Ko‘chirildi",
    copy_to_clipboard: "Buferga nusxalash",
    create_application_on_call: "Ilovalar va murojaatlarda avtomatik ravishda qo'ng'iroq kartasini yarating",
    create_lead: "Shikoyatni yaratish",
    create_lead_on_incoming_messages: "Noma'lardan kelgan xabarlar uchun shikoyat yarating",
    crm: "CRM",
    curator_assigned: "Kurator belgilangan",
    deadline: "Bajarish muddati",
    deal: "Sotib oluvchi",
    do_not_create_duplicates_on_call: "Agar faol shikoyat mavjud bo'lsa, qo‘ng‘iroq qilishda dublikatlar yaratilmasin",
    do_not_show_anymore: "Boshqa ko'rsatma",
    feedback: "Fikr",
    feedbacks: "Fikrlar",
    follow: "Bor",
    for_incoming: "Kiringanlar uchun",
    for_missed: "Chetlanganlar uchun",
    for_outcoming: "Chiqqanlar uchun",
    funnel_came_and_repeated: "Huni: erishildi va takrorlanadi",
    generate_key: "Kalit yaratish",
    has_overdue_tasks: "Mudat o'tgan vazifalarga ega",
    has_tasks: "Vazifalarga ega",
    has_today_task: "Bugungi vazifalarga ega",
    if_lead_has_no_responsible_then_assign_to_this_person_on_first_save: "Agar shikoyatga mas'ul shaxs belgilanmagan bo'lsa, u holda birinchi saqlashda ushbu shaxsni belgilang",
    inprogress: "Ishda",
    insert_script_one_time_instruction: "Bu skriptni <head> bo'limidagi saytga o'rnating",
    insert_widget_button_instruction: "Berilgan tugma kodini kerakli joyga o'rnating",
    instruction: "Ko'rsatmalar",
    integration_key: "Integratsiya kaliti",
    internal_phone: "Ichki telefon",
    lead_channel: "Shikoyat kanali",
    leave_comment: "Izoh qoldirish",
    manually_selected_user: "Qo‘llab-quvvatlash xizmatiga qo‘llanuvchi tanlangan",
    mark_as_completed: "Bajarilgan deb belgilang",
    mark_as_finished: "Bajarilgan deb belgilang",
    mark_as_in_progress: "Ishda deb belgilang",
    my_tasks: "Mening topshiriqlarim",
    no_rating: "Reyting yo‘q",
    nobody: "Hech kim",
    open_whatsapp: "Whatsappni oching",
    outgoing_calls_through: "Chiqish qo‘ng‘iroqlari orqali",
    overdue: "Yaroqlilik muddati o‘tgan",
    patient_has_no_future_visits_continue: "Bemor haqida qo‘ng‘iroq kartasi yo‘q, boshqa ko'rsatma",
    patient_not_derived: "Bemor aniqlanmagan",
    patient_not_derived_from_phone: "{phone} dan qo'ng'iroq - bemor aniqlanmagan",
    plan_treatments: "Davolash rejalari",
    planned: "Rejalashtirilgan",
    publish: "Nashr qilish",
    published: "Nashr qilingan",
    rating: "Reyting",
    recall: "Qayta qo'ng'iroq qilish",
    recall_on_lead: "Shikoyatga qayta qo'ng'iroq qilish '{title}'",
    record_myself: "Ro'yxatdan o'tish",
    record_on_visit: "Qabul qilish haqida yozuv",
    recorded_on_visit: "Qabul qilish haqida yozuv",
    responsible: "Mas’uliyatli",
    responsible_on_call: "Qo‘ng‘iroqda mas’ul",
    responsible_on_other_leads_not_created_manually: "Qolgan qo‘ng‘iroqlar uchun mas’uliyatli",
    sales_funnel: "Sotuvlar voronkasi",
    search_by_name: "Ism bo'yicha qidirish",
    search_by_phone: "Telefon raqami bo'yicha qidirish",
    see_later: "Keyinroq tomosha qiling",
    sipuni: "Sipuni",
    sipuni_integration_instructions: "Dentist Plus-ni Sipuni bilan birlashtirish uchun sizga kerak: <br>1. Sipuni-da hisob yaratish<br>2. API bo'limidagi Sipuni shaxsiy kabinetida integratsiya kalitini yarating, 'Raqamga qo'ng'iroq qilish' va 'PBX hodisalari' bandlarida 'xizmatni faollashtirish' tugmasini bosing, shuningdek, 'PBX hodisalari' bandida 'URL ni belgilang'. skript qabul qilinmoqda':<br>< span class='text-primary'>{url}</span><br>3. quyidagi maydonda sipuni funksiyasidan foydalanadigan klinika xodimlaridan foydalanuvchilarni ko'rsating va qolgan maydonlarni to'ldiring:",
    soon: "Tez",
    stage: "Bosqich",
    stages: "Bosqichlar",
    start_period: "Boshlanish vaqti",
    system_number: "Tizim raqami",
    task: "Topshiriq",
    task_date: "Topshiriq sanasi",
    task_type: "Topshiriq turi",
    tasks_and_calls: "Topshiriqlar va qo‘ng‘iroqlar",
    tilda_integration_instructions: "Tilda veb-saytingizdagi shakllardan vebhuklarni olish va stomatolog-plus tizimida mijozlarni yaratish uchun sizga quyidagilar kerak bo'ladi:<br />1. Tilda hisob qaydnomangizda Sayt sozlamalari → Shakllar → Webhook<br/>2-ga o'ting. Webhook URL maydoniga <span class='text-primary'>{url}</span><br>3 kiriting. API METHOD maydonida 'HEADER' ni tanlang, 'Maydon nomi'da 'API-KEY' ni to'ldiring va 'Api key' maydonida quyida yaratishingiz mumkin bo'lgan kalitni nusxalang.<br />4. Kengaytirilgan sozlamalarda “Cookie-fayllarni yuborish”ni faollashtiring.<br />5. Shakllardagi quyidagi maydonlar tish shifokori-plyus tizimida avtomatik ravishda to'ldiriladi: <b>ism, telefon, elektron pochta, sharh</b>.",
    treatment_plan_signed: "Davolash rejasi imzolangan",
    treatment_refused: "Davolashdan rad etildi",
    treatment_started: "Davolash boshlandi",
    turn_on_chat_panel: "Chat panelni yoqish",
    uis: "UIS",
    uis_integration_instructions: "Dentist Plus-ni UIS bilan birlashtirish uchun sizga kerak: <br>1. UIS-da hisob yaratish<br>2. UIS shaxsiy kabinetida kerakli foydalanuvchi sozlamalarini oching (masalan, Administrator) va API funktsionaliga kirish bo'limida 'API kalitini ishlatish' sozlamasini faollashtiring<br/>3. 'Bildirishnomalar' menyusida 2 ta bildirishnoma yarating: javob kutish va muloqot yakunlanishi. Har ikki holatda ham HTTP bildirishnomalari usulini faollashtiring va URL maydonida havolani ko'rsating:<br><span class='text-primary'>{url}</span><br>4. quyidagi maydonda uis funksiyasidan foydalanadigan klinika xodimlaridan foydalanuvchilarni ko'rsating va ularning virtual raqamlarini (qo‘ng‘iroqlarni qabul qilish va boshlash uchun) to'ldiring:",
    unpublish: "Nashrdan olib tashlash",
    user_add_id: "Muallif",
    view: "Ko‘rib chiqish",
    visit_confirm: "Qabulni tasdiqlash",
    without_tasks: "Vazifa yo‘q",
    write_in_messenger: "Messengerga yozing",
    you_also_can_use_link: "Siz to'g'ridan -to'g'ri shaklga havolani ishlatishingiz mumkin",
}
