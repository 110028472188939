export const salaryTypes = {
    after: { id: 'after', title: 'after_discounts' },
    before: { id: 'before', title: 'before_discounts' }
}

export const percentTypes = {
    after_technic: { id: 'after_technic', title: 'after_technic_deduction' },
    before_technic: { id: 'before_technic', title: 'before_technic_deduction' }
}

export const salaryAccrualTypes = {
    payment: { id: 'payment', title: 'only_after_payment_received' },
    all: { id: 'all', title: 'all_visits' }
}

export const bonusUseTypes = {
    0: { id: 0, title: 'no' },
    1: { id: 1, title: 'yes' }
}

export const managerPercentTypes = {
    visit_add: { id: 'visit_add', title: 'to_the_one_who_made_record' },
    payment_received: { id: 'payment_received', title: 'to_the_one_who_accepted_payment' }
}

export const taskImportances = [
    { id: 'low', title: 'low' },
    { id: 'medium', title: 'medium' },
    { id: 'high', title: 'high' },
]

export const taskTypes = [
    { id: 'call', title: 'call_noun' },
    { id: 'task', title: 'task' },
]

export const recordStatuses = [
    { id: 1, title: 'not_confirmed' },
    { id: 2, title: 'confirmed' },
    { id: 3, title: 'came' },
    { id: 4, title: 'in_chair' },
    { id: 5, title: 'did_not_come' },
]

export const recordStatusesWithCancelled = [
    { id: 1, title: 'not_confirmed' },
    { id: 2, title: 'confirmed' },
    { id: 3, title: 'came' },
    { id: 4, title: 'in_chair' },
    { id: 5, title: 'did_not_come' },
    { id: 0, title: 'cancelled' },
]

export const taskStatuses = [
    { id: 'planned', title: 'planned' },
    { id: 'inprogress', title: 'inprogress' },
    { id: 'done', title: 'done' },
    { id: 'cancelled', title: 'cancelled' },
    { id: 'for_rework', title: 'to_rework' },
]

export const recordTypes = [
    { id: 'treatment', title: 'treatment' },
    { id: 'consultation', title: 'consultation' },
    { id: 'diagnostics', title: 'diagnostics' },
    { id: 'checkup', title: 'checkup' },
    { id: 'sanation', title: 'sanation' },
    { id: 'curation', title: 'curation' },
    { id: 'online_consultation', title: 'online_consultation' },
    { id: 'block', title: 'block' },
]

export const warehouseOperationTypes = [
    { id: "addition", title: "addition" },
    { id: "movement_from", title: "from_warehouse" },
    { id: "movement_to", title: "to_warehouse" },
    { id: "return", title: "return" },
    { id: "write_off", title: "write_off" },
    { id: "consumption", title: "consumption" },
    { id: "inventarization", title: "inventarization" }
]

export const warehouseOperationTypesToAdd = [
    { id: "addition", title: "addition" },
    { id: "movement_from", title: "from_warehouse" },
    { id: "return", title: "return" },
    { id: "write_off", title: "write_off" },
    { id: "inventarization", title: "inventarization" },
    { id: "consumption_manual", title: "consumption_manual" }
]

export const templateTypesToModelNames = {
    complaints_templates      : 'complaint_template',
    diagnosis                 : 'diagnosis_template',
    bite_templates            : 'bite_template',
    development_templates     : 'development_template',
    diseases_templates        : 'disease_template',
    inspection_templates      : 'inspection_template',
    medication_templates      : 'medication_template',
    objectively_templates     : 'objective_template',
    plansurvey_templates      : 'plansurvey_templates',
    recommendations_templates : 'recommendations_templates',
    research_templates        : 'research_template',
    subjectively_templates    : 'subjective_template'
}


export const roles = {
    2: 'superadministrator',
    3: 'administrator',
    4: 'doctor',
    5: 'patient',
    6: 'technic',
    7: 'assistant',
    8: 'curator',
}

export const clinicAccountTypes = {
    0: { id: 0, title: 'test' },
    1: { id: 1, title: 'demo' },
    2: { id: 2, title: 'abonplata' },
    3: { id: 3, title: 'purchase' }
}

export const clinicLanguages = {
    'russian': { id: 'russian', title: 'russian' },
    'ukrainian': { id: 'ukrainian', title: 'ukrainian' },
    'uzbek': { id: 'uzbek', title: 'uzbek' },
    'english': { id: 'english', title: 'english' },
}


export const anketaCategories = {
    other: 'other',
    female: 'for_female_patients',
    diseases: 'diseases_passed_and_current',
    allergies: 'allergic_reactions',
    anamnesis: 'anamnesis'
}

export const templateGroups = [
    'teeth_plansurvey',
    'teeth_medication',
    'teeth_recommendations',
    'complaints',
    'inspection',
    'objectively',
    'subjectively',
    'diseases',
    'development',
    'bite',
    'research',
    'description'
]

export const planTemplateGroups = [
    'diagnoses',
    'services',
    'planned_treatment',
    'planned_result',
    'description',
    'date_to'
]

export const currencyForJson = [
    'usd', 'rub', 'kzt', 'uah'
]

export const currenciesForBilling = [
    { id: 'usd', title: 'usd' },
    { id: 'rub', title: 'rub' },
    { id: 'kzt', title: 'kzt' },
    { id: 'uah', title: 'uah' },
]

export const typesOfNotifications = [
    { id: 'before_visit', title: 'before_visit' },
    { id: 'after_visit', title: 'after_visit' },
    { id: 'visit_created', title: 'visit_created' },
    { id: 'visit_cancel', title: 'visit_cancel' },
    // { id: 'treatment_finished', title: 'treatment_finished' },
    { id: 'crm_application_created', title: 'crm_application_created' },
    { id: 'task_created', title: 'task_created' },
    { id: 'birthday', title: 'birth_day' },
    { id: 'patient_came', title: 'patient_came' },
    { id: 'treatment_plan_created', title: 'treatment_plan_created' },
    { id: 'plan_status_changed', title: 'plan_status_changed' },
    { id: 'complex_status_changed', title: 'complex_status_changed' },
    { id: 'since_last_visit', title: 'since_last_visit' },
    { id: 'visit_time_changed', title: 'visit_time_changed' },
    { id: 'after_service', title: 'after_service' },
]

export const timeTypes = [
    { id: 'minutes', title: 'minutes' },
    { id: 'hours', title: 'of_hours' },
    { id: 'days', title: 'of_days' },
]

export const notificationRoles = [
    { id: 2, title: 'superadministrator' },
    { id: 3, title: 'administrator' },
    { id: 4, title: 'doctor' },
    { id: 5, title: 'patient' },
]


export const f057Types = [
    { id: 'therapist', title: 'therapist' },
    { id: 'orthodontist', title: 'orthodontist' },
    { id: 'orthopedist', title: 'orthopedist' },
]


export const teethLabels = {
    "В": "top_jaw",
    "Н": "bottom_jaw",
    "Р": "oral_cavity",
}


export const tariffsToChangeOurself = [
    { id: 2, title: 'Standard' },
    { id: 3, title: 'Premium' },
    { id: 13, title: 'Basic' },
]


export const leadStatuses = [
    { id: 'new', title: 'new', color: '#DD1515' },
    { id: 'not_reached', title: 'not_reached', color: 'rgba(7, 135, 201)' },
    { id: 'thinking', title: 'thinking', color: '#FEC943' },
    { id: 'recorded', title: 'recorded', color: '#5E9246' },
    { id: 'rejected', title: 'rejected', color: '#5D6D7C' },
    { id: 'stale', title: 'stale', color: '#5D6D7C' },
]

export const egiszStatuses = [
    { id: 'draft', title: 'draft', color: '#5D6D7C' },
    { id: 'requires_attention', title: 'requires_attention', color: '#DD1515' },
    { id: 'signed_by_doctor', title: 'signed_by_doctor', color: '#FEC943' },
    { id: 'signed_by_main_doctor', title: 'signed_by_main_doctor', color: '#5E9246' },
    { id: 'sent_to_egisz', title: 'sent_to_egisz', color: 'rgba(7, 135, 201)' },
]


export const planTreatmentStatuses = [
    { id: 'preliminary', title: 'preliminary', color: '#5D6D7C' },
    { id: 'confirmed', title: 'confirmed', color: '#FEC943' },
    { id: 'active', title: 'active', color: 'rgba(7, 135, 201)' },
    { id: 'treatment_complete', title: 'treatment_complete', color: '#5E9246' },
    { id: 'stopped', title: 'stopped', color: '#5D6D7C' },
    { id: 'rejected', title: 'rejected', color: '#DD1515' },
]


export const leadSources = [
    { id: 'call', title: 'call_noun' },
    { id: 'whatsapp', title: 'whatsapp' },
    { id: 'telegram', title: 'telegram' },
    { id: 'crm-form', title: 'crm-form' },
    { id: 'online_booking', title: 'online_booking' },
    { id: 'va', title: 'va' },
    { id: 'Tilda', title: 'Tilda' },
    { id: 'other', title: 'other' },
]

export const months = [
    { id: '01', title: 'january' },
    { id: '02', title: 'february' },
    { id: '03', title: 'march' },
    { id: '04', title: 'april' },
    { id: '05', title: 'may' },
    { id: '06', title: 'june' },
    { id: '07', title: 'july' },
    { id: '08', title: 'august' },
    { id: '09', title: 'september' },
    { id: '10', title: 'october' },
    { id: '11', title: 'november' },
    { id: '12', title: 'december' },
]

export const cashOperations = [
    { id: 'injection', title: 'filling' },
    { id: 'withdrawal', title: 'withdrawal' },
]

export const f057Categories = [
    { type: 'title', title: 'Терапевт' },
    { type: 'title', title: 'Наложение пломб по поводу' },
    { type: 'category', title: 'Неосложнённый кариес' },
    { type: 'item', title: 'Поверхностный', id: 'cavity_low' },
    { type: 'item', title: 'Средний', id: 'cavity_medium' },
    { type: 'item', title: 'Глубокий', id: 'cavity_high' },
    { type: 'category', title: 'Осложненный кариес' },
    { type: 'item', title: 'Пульпит', id: 'pulpit' },
    { type: 'item', title: 'Периоднотит', id: 'periodontit' },
    { type: 'item', title: 'Некариозионные поражения зубов', id: 'nekarioz' },
    { type: 'category', title: 'Профилактическая работа' },
    { type: 'item', title: 'Осмотрено в плановом порядке', id: 'plan_reviewed' },
    { type: 'item', title: 'Нуждалось в санации из числа осмотренных', id: 'need_sanation' },
    { type: 'item', title: 'Вылечено из числа нуждающихся в санации', id: 'need_sanation_cured' },
    { type: 'category', title: 'Гингивит' },
    { type: 'item', title: 'Катаральный', id: 'cataral' },
    { type: 'item', title: 'Гипертрофический', id: 'hypertroph' },
    { type: 'item', title: 'Язвенный', id: 'ulcerative' },
    { type: 'title', title: 'Закончен курс лечения по поводу' },
    { type: 'category', title: 'Парадонтит' },
    { type: 'item', title: 'Легкая степень', id: 'paradontit_low' },
    { type: 'item', title: 'Средняя степень', id: 'paradontit_medium' },
    { type: 'item', title: 'Тяжелая степень', id: 'paradontit_high' },
    { type: 'category', title: 'Парадонтоз' },
    { type: 'item', title: 'Парадонтоз', id: 'paradontoz' },
    { type: 'item', title: 'Заболевания слизистой', id: 'slizist' },
    { type: 'title', title: 'Хирургические вмешательства' },
    { type: 'category', title: 'Удалено зубов' },
    { type: 'item', title: 'Всего', id: 'removed_total' },
    { type: 'item', title: 'По поводу заболеваний пародонта', id: 'removed_paradont' },
    { type: 'item', title: 'Экстренные операции', id: 'extra_oper' },
    { type: 'item', title: 'Имплантация (Число имплантов)', id: 'implant' },
    { type: 'item', title: 'Плановые операции в т.ч. операции нтр', id: 'planned_oper' },
    { type: 'item', title: 'Санировано', id: 'sanated' },

    { type: 'title', title: 'Ортодонт' },
    { type: 'category', title: 'Внутриротовые несъемные аппараты' },
    { type: 'item', title: 'Механического действия', id: 'non_removing_mechanical' },
    { type: 'item', title: 'Функционального действия', id: 'non_removing_functional' },
    { type: 'category', title: 'Внутриротовые съемные аппараты' },
    { type: 'item', title: 'Механического действия', id: 'removing_mechanical' },
    { type: 'item', title: 'Функционального действия', id: 'removing_functional' },
    { type: 'category', title: 'Аппараты сочетанного действия' },
    { type: 'item', title: 'Аппараты сочетанного действия', id: 'mixed_apparatus' },
    { type: 'category', title: 'Протезы' },
    { type: 'item', title: 'Несъемные', id: 'prothez_non_removing' },
    { type: 'item', title: 'Съемные', id: 'prothez_removing' },
    { type: 'item', title: 'В том числе ортопедические', id: 'prothez_orthopedic' },
    { type: 'category', title: 'Число лиц, которым закончено ортодонтическое лечение' },
    { type: 'item', title: 'с аномалиями отдельных зубов', id: 'orthodont_anomaly_teeth' },
    { type: 'item', title: 'с аномалиями зубных рядов', id: 'orthodont_anomaly_rows' },
    { type: 'item', title: 'с сагиттальными аномалиями прикуса', id: 'orthodont_anomaly_prikus' },
    { type: 'item', title: 'с трансверзальными аномалиями прикуса', id: 'orthodont_anomaly_tranverse' },
    { type: 'item', title: 'с вертикальными аномалиями прикуса', id: 'orthodont_anomaly_vertical' },

    { type: 'title', title: 'Ортопед' },
    { type: 'category', title: 'Искусственные коронки (одиночные на зубах)' },
    { type: 'item', title: 'металлические', id: 'orthoped_metal' },
    { type: 'item', title: 'штампованные', id: 'orthoped_stamped' },
    { type: 'item', title: 'литые', id: 'orthoped_filled' },
    { type: 'item', title: 'Пластмассовые коронки и зубы (прямые)', id: 'orthoped_plastic_direct' },
    { type: 'item', title: 'Пластмассовые коронки и зубы (непрямые)', id: 'orthoped_plastic_indirect' },
    { type: 'item', title: 'виниры', id: 'orthoped_vinirs' },
    { type: 'item', title: 'керамические м/к', id: 'orthoped_ceramics_mk' },
    { type: 'item', title: 'керамические цирконивые', id: 'orthoped_ceramics_circonium' },
    { type: 'item', title: 'керамические цельно-керамические', id: 'orthoped_ceramics_whole' },
    { type: 'category', title: 'Коронки на имплантах одиночные' },
    { type: 'item', title: 'металлокерамические', id: 'orthoped_cor_metal' },
    { type: 'item', title: 'коронок циркон', id: 'orthoped_cor_cirkonium' },
    { type: 'item', title: 'цельно керамические', id: 'orthoped_cor_whole' },
    { type: 'category', title: 'Мостовидные протезы' },
    { type: 'item', title: 'литых зубов метал', id: 'orthoped_filled_teeth_metal' },
    { type: 'item', title: 'литых зубов металлокерамика', id: 'orthoped_filled_teeth_metalceramics' },
    { type: 'item', title: 'литых зубов цельно-керамические', id: 'orthoped_filled_teeth_whole' },
    { type: 'item', title: 'литых зубов', id: 'orthoped_filled_teeth' },
    { type: 'item', title: 'зубов м/к', id: 'orthoped_most_mk' },
    { type: 'item', title: 'цельно-керамические', id: 'orthoped_most_ceramics' },
    { type: 'category', title: 'Штифтовые зубы' },
    { type: 'item', title: 'Штифтовые зубы', id: 'orthoped_stift' },
    { type: 'category', title: 'Съемные протезы' },
    { type: 'item', title: 'Частичные пластиночные', id: 'orthoped_prothes_plastin' },
    { type: 'item', title: 'Полные пластмасса', id: 'orthoped_prothes_plastic' },
    { type: 'item', title: 'Частичные бюгельные на аттачменте', id: 'orthoped_prothes_bugel_attach' },
    { type: 'item', title: 'Частичные бюгельные на кламмерах', id: 'orthoped_prothes_bugel_clammer' },
    { type: 'item', title: 'Полные съемные пластиночные протезы', id: 'orthoped_prothes_full_removing' },
    { type: 'category', title: 'Лица, получившие протез' },
    { type: 'item', title: 'Всего', id: 'orthoped_prothes_total_people' },
    { type: 'item', title: 'В том числе бесплатно', id: 'orthoped_prothes_free_people' },
]

export const superUserRoles = [
    { id: "superadmin", title: "superadmin" },
    { id: "supermanager", title: "supermanager" },
    { id: "manager", title: "manager" },
    // { id: "tech_specialist", title: "tech_specialist" },
]

export const permanentPatientOptions = [
    { id: "more_than_one_visit", title: "more_than_one_visit" },
    { id: "more_than_one_day", title: "more_than_one_day" },
]

export const jetAPIStatuses = [
    { "code": 0, "status": "Ошибка оператора", "type": "финальный" },
    { "code": 1, "status": "Передано оператору", "type": "промежуточный" },
    { "code": 2, "status": "Доставлено", "type": "финальный" },
    { "code": 3, "status": "Просрочено", "type": "финальный" },
    { "code": 4, "status": "Удалено", "type": "финальный" },
    { "code": 5, "status": "Невозможно доставить", "type": "финальный" },
    { "code": 6, "status": "Принято", "type": "промежуточный" },
    { "code": 7, "status": "Неизвестная ошибка", "type": "финальный" },
    { "code": 8, "status": "Отклонено", "type": "финальный" },
    { "code": 9, "status": "Ошибка сервера", "type": "финальный" },
    { "code": 10, "status": "Необрабатываемая ошибка", "type": "финальный" },
    { "code": 11, "status": "Неверный номер", "type": "финальный" },
    { "code": 12, "status": "Запрещено", "type": "финальный" },
    { "code": 13, "status": "Недостаточно средств", "type": "финальный" },
    { "code": 14, "status": "Ожидает отправки", "type": "промежуточный" },
    { "code": 15, "status": "В обработке", "type": "промежуточный" },
    { "code": 16, "status": "Прочитано", "type": "финальный" },
    { "code": 17, "status": "Превышен лимит запросов", "type": "финальный" },
    { "code": 21, "status": "На модерации", "type": "промежуточный" },
    { "code": 22, "status": "Модерация отклонена", "type": "финальный" },
    { "code": 25, "status": "Ограничения отправки", "type": "финальный" },
    { "code": 26, "status": "Ограничения длины сообщений", "type": "финальный" },
    { "code": 27, "status": "Ограничения отправки в Казахстан", "type": "промежуточный" },
    { "code": 28, "status": "Ограничение по оператору", "type": "финальный" },
    { "code": 31, "status": "Ошибка при создании", "type": "финальный" },
    { "code": 32, "status": "WhatsApp не оплачен", "type": "финальный" },
    { "code": 33, "status": "Неверные параметры отправки", "type": "финальный" },
    { "code": 34, "status": "Ограничения отправки", "type": "финальный" },
    { "code": 35, "status": "Превышен лимит запросов на доставку", "type": "финальный" },
    { "code": 44, "status": "WhatsApp незарегистрированный номер", "type": "финальный" },
    { "code": 45, "status": "Сессия WhatsApp не запущена", "type": "финальный" },
    { "code": 50, "status": "Telegram не авторизован", "type": "финальный" },
    { "code": 555, "status": "В черном списке", "type": "финальный" },
];

export const countries = {
    UKRAINE: 1,
    POLAND: 2,
    RUSSIA: 3,
    UNKNOWN: 4,
    KAZAKHSTAN: 5,
    BELARUS: 6,
    AZERBAIJAN: 7,
    ARMENIA: 8,
    MOLDOVA: 9,
    KYRGYZSTAN: 10,
    TAJIKISTAN: 11,
    UZBEKISTAN: 12
}

export const outgoingCallTypes = [
    { id: 'standard', title: 'standard' },
    { id: 'binotel', title: 'binotel' },
    { id: 'sipuni', title: 'sipuni' },
    { id: 'uis', title: 'uis' },
]

export const countryPaySystems = [
    { id: 'ioka', title: 'ioka' },
    { id: 'robokassa', title: 'robokassa' },
]

export const notificationStatuses = [
    { id: 'sent', title: 'sent' },
    { id: 'error', title: 'error' },
    { id: 'cancelled', title: 'cancelled' },
    { id: 'waiting', title: 'waiting' },
]

export const notificationTypes = [
    { id: 'manual', title: 'manual' },
    { id: 'notification_setting', title: 'notification_setting' },
    { id: 'mailing', title: 'mailing' },
]

export const aqsiTaxModes = [
    { id: 1, title: "20%" },
    { id: 2, title: "10%" },
    { id: 3, title: "20/120" },
    { id: 4, title: "10/110" },
    { id: 5, title: "0%" },
    { id: 6, title: "not_subject_to_vat" },
]

export const kkmTaxModes = [
    { id: 20, title: "20%" },
    { id: 10, title: "10%" },
    { id: 120, title: "20/120" },
    { id: 110, title: "10/110" },
    { id: 0, title: "0%" },
    { id: -1, title: "not_subject_to_vat" },
]

export const leadCallResponsibleOptions = [
    { id: 'admins', title: 'all_administrators' },
    { id: 'callee', title: 'callee' },
    { id: 'user_id', title: 'manually_selected_user' },
    { id: 'nobody', title: 'nobody' },
]

export const leadResponsibleOptions = [
    { id: 'admins', title: 'all_administrators' },
    { id: 'user_id', title: 'manually_selected_user' },
    { id: 'nobody', title: 'nobody' },
]

export const planTreatmentTypes = [
    { id: 'recommended', title: 'recommended' },
    { id: 'alternative', title: 'alternative' },
    { id: 'additional', title: 'additional' },
]

export const funnelPatientStatuses = [
    { id: 'new', title: 'new', color: '#5D6D7C' },
    { id: 'curator_assigned', title: 'curator_assigned', color: '#FEC943' },
    { id: 'treatment_plan_created', title: 'treatment_plan_created', color: 'rgba(7, 135, 201)' },
    { id: 'treatment_plan_signed', title: 'treatment_plan_signed', color: '#5E9246' },
    { id: 'treatment_started', title: 'treatment_started', color: '#5D6D7C' },
    { id: 'treatment_finished', title: 'treatment_finished', color: '#5D6D7C' },
    { id: 'treatment_refused', title: 'treatment_refused', color: '#DD1515' },
]
